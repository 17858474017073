import React from 'react'
import { graphql, navigate } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Layout,
  Container,
  Headline,
  SEO,
  SectionRecentPosts,
  SectionBanner,
} from '../components'
import { Button } from '../components/Button'
import { SectionNewsletter } from '../components/Section/SectionNewsletter'
import { SectionPostsView } from '../components/Section/SectionPostsView'

type BlogPostProps = {
  data: {
    wpPost: {
      title: string
      content: string
      date: string
      categories: {
        nodes: Array<{
          id: string
          slug: string
          name: string
        }>
      }
      author: {
        node: {
          firstName: string
          lastName: string
        }
      }
      featuredImage: {
        node: {
          mediaItemUrl: string
          altText: string
        }
      }
      seo: {
        title: string
        metaDesc: string
        opengraphImage: {
          localFile: {
            publicURL: string
          }
        }
      }
    }
  }
}

const BlogPost = ({ data }: BlogPostProps): React.ReactElement => {
  const {
    featuredImage,
    content,
    date,
    author,
    categories,
    seo: { title, metaDesc, opengraphImage },
  } = data.wpPost
  const { mediaItemUrl, altText } = featuredImage?.node
  const { locale } = useLocalization()

  const oldDateFormat = new Date(date)
  const newDateFormat = oldDateFormat?.toLocaleDateString()

  return (
    <>
      <SEO
        wordpressTitle={title}
        description={metaDesc}
        socialImageRelativeUrl={opengraphImage?.localFile.publicURL}
      />
      <Layout>
        <Container>
          <div className="lg:flex">
            <div className="lg:flex lg:flex-col lg:mr-8 sm:flex-col hidden">
              <SectionPostsView numberOfPosts={3} subTitle="Weitere Artikel" />
            </div>
            <div className="max-w-3xl mx-auto">
              <div>
                <img src={mediaItemUrl} alt={altText} />
                <Headline
                  tagName="h1"
                  className="mt-10 mb-3"
                  variant="underline"
                >
                  {title}
                </Headline>
                <p className="mt-10 mb-4">{newDateFormat}</p>
                {categories.nodes.some((item) => item.slug === 'team') && (
                  <p className="font-thin italic">
                    Interview von{' '}
                    {`${author?.node.firstName} ${author?.node.lastName}`}{' '}
                  </p>
                )}
                <div className="border-b-8 border-solid border-white w-20 mt-10 mb-10" />
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: content }}
                  className="formattedHTML"
                />
              </div>
              <div className="pt-10 max-w-4xl mx-auto">
                <Button
                  className="p-0"
                  variant="link"
                  onClick={() => navigate(`/${locale}/blog`)}
                >
                  Züruck zur Artikelübersicht
                </Button>
              </div>
              <SectionRecentPosts
                numberOfPosts={3}
                showMore={false}
                showSmaller
              />
              <SectionBanner
                title="Jetzt loslegen"
                text="Sie wollen den Zahlungsverkehr von Morgen schon heute nutzen und nachhaltig Wirtschaften? Nutzen Sie Ihre Gelgenheit live unsere Plattform und uns kennenzulernen."
                image={
                  <StaticImage
                    src="../images/manuwithstars.png"
                    alt="Person mit Glitzer"
                    style={{ maxWidth: 450 }}
                    // className="md:w-"
                  />
                }
                singlePost
                actionLinks={[
                  {
                    label: 'Termin buchen',
                    url: 'demo',
                  },
                ]}
              />
              <SectionNewsletter containerClass="" page="Startseite" />
            </div>
          </div>
        </Container>
      </Layout>
    </>
  )
}
export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      date
      categories {
        nodes {
          id
          slug
          name
        }
      }
      author {
        node {
          firstName
          lastName
        }
      }
      featuredImage {
        node {
          altText
          mediaItemUrl
          date(formatString: "DD.MM.YYYY HH:MM")
        }
      }
      seo {
        title
        metaDesc
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

export default BlogPost
