import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import cx from 'classnames'
import { useLocalization, LocalizedLink as Link } from 'gatsby-theme-i18n'

import { Container } from '../Layout'
import { PostPreview } from '../PostPreview'
import { Section, SectionProps } from './Section'
import { Headline } from '..'

type SectionPostsViewProps = SectionProps & {
  numberOfPosts?: number
  subTitle?: string
}

const defaultProps: Partial<SectionPostsViewProps> = {
  numberOfPosts: 3,
  subTitle: '',
}

const SectionPostsView = (props: SectionPostsViewProps): React.ReactElement => {
  const { numberOfPosts, subTitle } = props
  const { locale } = useLocalization()
  const {
    allWpPost: { edges },
  } = useStaticQuery(graphql`
    query RecentPostsQuerySide {
      allWpPost(limit: 3, sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            slug
            excerpt
            date
            categories {
              nodes {
                id
                slug
                name
              }
            }
            author {
              node {
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  `)
  const { containerClass } = props
  const posts = edges.slice(0, numberOfPosts).map(({ node }: any) => node)

  return (
    <Section containerClass={containerClass} stickySidePosts>
      <Container>
        {posts && !!posts.length && (
          <div className="grid grid-cols-1 gap-6 ">
            <Headline variant="underline" fontSize="xsmall">
              {subTitle}
            </Headline>
            {posts.map(
              (
                { title, slug, categories, excerpt, author, date }: any,
                i: number
              ) => {
                const isHighlight = i === 3
                return (
                  <div
                    key={slug}
                    className={cx('h-32', {
                      'sm:col-span-1 lg:col-span-3 lg:h-post-preview-highlight':
                        isHighlight,
                    })}
                  >
                    <Link to={`/blog/${slug}`} language={locale}>
                      <PostPreview
                        title={title}
                        subtitle={
                          <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                        }
                        labels={categories.nodes}
                        isHighlight={isHighlight}
                        sectionPostsView
                        author={author.node}
                        date={date}
                      />
                    </Link>
                  </div>
                )
              }
            )}
          </div>
        )}
      </Container>
    </Section>
  )
}
SectionPostsView.defaultProps = defaultProps

export { SectionPostsView }
